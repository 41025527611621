@font-face {
  font-family: "Oswald-Bold";
  src: local("Oswald-Bold"), url(../fonts/Oswald-Bold.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "Oswald-Regular";
  font-weight: bold;
  src: local("Oswald-Regular"),
    url(../fonts/Oswald-Regular.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "Oswald-Light";
  font-weight: lighter;
  src: local("Oswald-Light"), url(../fonts/Oswald-Light.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "SplineSansMono-Regular";
  src: local("SplineSansMono-Regular"),
    url(../fonts/SplineSansMono-Regular.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "PTSans-Bold";
  src: local("PTSans-Bold"), url(../fonts/PTSans-Bold.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "PTSans-Regular";
  src: local("PTSans-Regular"),
    url(../fonts/PTSans-Regular.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

body {
  margin: 0;
  background-color: #f1f1f1;
  font-family: PTSans;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-scroll {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
